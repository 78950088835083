class Spawn extends Layer
	points : []
	count : 3
	diam : 96
	ratio: 1.25
	petal_num: 13
	petal_ratio: 0.25
	selected : null
	offset: null
	webgl : false
	grow_speed : 1.8
	setup : () ->
		@diam = @size / 10
		for i in [1..@count]
			v = createVector random(@diam * 0.5, @size - @diam * 0.5), random(@diam * 0.5 * @ratio, @size - @diam * 0.5 * @ratio)
			p =
				pos : v
				time : 0
			@points.push p
		@update()
		@draw()


	draw : (delta = 0, time = 0, external = false) ->
		# @image.push()
		# @layers.goal.draw delta, time
		# @image.pop()
		if not external
			@image.clear()
			# @image.background Colors.white

			# @image.fill Colors.red
			# @image.circle @layers.goal.pos.x, @layers.goal.pos.y, @layers.goal.margin * 0.85
			@image.fill Colors.red
			@image.circle @layers.goal.pos.x, @layers.goal.pos.y, @layers.goal.margin
			@image.fill Colors.yellow
			@image.circle @layers.goal.pos.x, @layers.goal.pos.y, @layers.goal.diam
		@image.stroke Colors.green
		@image.strokeWeight @petal_ratio * @diam * 2
		@image.noFill()
		angle = TWO_PI /  @petal_num

		if external
			@image.fill Colors.green
			@image.stroke Colors.green
		else
			@image.fill Colors.green
			@image.stroke Colors.green
		for p in @points
			if p.time < 1
				p.time = constrain p.time + delta * @grow_speed, 0, 1
			t = p.pos
			beginShape(POINTS)
			for a in [0..TWO_PI] by angle
				x = cos(a) * @diam * 0.5
				y = sin(a) * @diam * 0.5
				@image.vertex t.x + x, t.y + y
			endShape()
			@image.circle t.x, t.y, @diam * p.time

	# flower_at : (p) ->
	# 	for t, i in @points
	# 		if collidePointCircle p.x, p.y, t.x, t.y, @diam * @ratio
	# 			return i
	# 	null
	#
	# select : (p) ->
	# 	p = @pixel p
	# 	@selected = @flower_at p
	# 	if @selected?
	# 		@offset = p.sub @points[@selected]
	place_point : (p) ->
		@points[0].pos.x = @size * p.x
		@points[0].pos.y = @size * p.y
		@points[0].time = 0
		@points.unshift @points.pop()
		@update()
		@draw()
		toner.noise_env.triggerAttackRelease("8n")


	tap : (p) ->
		@place_point p
		# for p, i in @points
		# 	for q in [i+1..@points.length]
		# 		@points[i] = @separate_circles @points[i], @diam, @points[q], @diam * @ratio
	press : (p) ->
		@place_point p
		# @select p
		# @draw()
	pressup : (p) ->
		# @selected = null
		# @draw()
	panstart : (p) ->
		# @select p
		# @draw()
	get_radius : () -> @diam * 0.5 * @ratio
	get_pos: () ->
		dir = random( Math.PI * 2)
		len = random @diam
		rand_point = random(@points).pos
		pos = createVector cos( dir ) * len, sin( dir ) * len
		pos.add rand_point
		@separate_circles pos, 1, rand_point, @diam

	panend : (p) ->
		# @selected = null
		# @draw()

	update : () ->
		for p, i in @points
			r = @get_radius()
			s = @size - @diam * @ratio
			p.pos = @separate_circles p.pos, @diam, @layers.goal.pos, @layers.goal.margin

			for q, j in @points
				if i isnt j
					p.pos = @separate_circles p.pos, @diam, q.pos, @diam

			# p.pos = @constrain_point @points[i].pos, s, s, r, r
			p.pos = @wrap_point @points[i].pos, s, s, r, r

	pan : (p, delta) ->
		# if @selected?
		# 	target_pos = @pixel p
		# 	@points[@selected] = target_pos.sub @offset
		# 	r = @get_radius()
		# 	s = @size - @diam * @ratio
		# 	@points[@selected] = @constrain_point @points[@selected], s, s, r, r
		# 	@update()
		# @draw()

	blend : () ->
		@draw_window @layers.goal.image, @window
		@draw_window @image, @window
